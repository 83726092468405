export const vitaEntries = [
  {
    year: '1958',
    text: 'geboren in Schleswig Holstein'
  },
  {
    year: '1964 - 1972',
    text: 'Übersiedlung nach Istanbul Türkei\nBesuch der deutschen Schule in Istanbul'
  },
  {
    year: '1977',
    text: 'Abitur in Plön'
  },
  {
    year: '1977 - 1984',
    text: 'Studium der Malerei an der Hochschule der Künste, Berlin, bei Prof. Hoelzmann, Prof. Schmettau und Prof. Bachmann'
  },
  {
    year: '1983',
    text: 'Meisterschülerin bei Prof. Bachmann'
  },
  {
    year: '1986 - 1987',
    text: 'Stipendium des DAAD, Paris'
  },
  {
    year: '1986 - 1988',
    text: 'Stipendium der Karl-Hofer-Gesellschaft, Berlin'
  },
  {
    year: '2000 - 2001',
    text: 'Stipendium der Gustav Kettel-Stiftung'
  },
  {
    year: '2001',
    text: 'Film: Figur und Raum von R. Schmidt über G. Schlesselmann, Interview Jutta Brückner, 29.40 Min'
  },
  {
    year: '2003 - 2006',
    text: 'Dozentin an der Universität der Künste, Berlin'
  },
  {
    year: '2013',
    text: 'Förderung durch den Projektfonds kulturelle Bildung, Berlin\n1. Preisträgerin des Mete Eksi Preis, für künstl.Leitung'
  },
  {
    year: '2014',
    text: '1. Preisträgerin des Wettbewerbs Kinder zum Olymp, für künstl. Leitung'
  },
];
