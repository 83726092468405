export const fluchtenData = {
  name: "Odysseen",
  img: "series/fluchten/big/AufDenFersen.jpg",
  gridCols: 6,
  gridAspectRatio: 26 / 14.5,
  text:"Schutzlose Suchbewegung ins Ungewisse\n" +
    "heraus, aus der vertrauten Zeit,\n" +
    "der Ort ist überall,\n" +
    "die Person ist Jeder,\n" +
    "ohne jede Sicherheit",
  pictures: [
    {
      id: 1,
      title: "Auf den Fersen",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/AufDenFersen.jpg",
      imgDefault: "series/fluchten/big/AufDenFersen.jpg",
      imgFull: "series/fluchten/big/AufDenFersen.jpg",
    },
    {
      id: 2,
      title: "Ausbruch",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/Ausbruch.jpg",
      imgDefault: "series/fluchten/big/Ausbruch.jpg",
      imgFull: "series/fluchten/big/Ausbruch.jpg",
    },
    {
      id: 3,
      title: "Das letzte Band",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/DasLetzteBand.jpg",
      imgDefault: "series/fluchten/big/DasLetzteBand.jpg",
      imgFull: "series/fluchten/big/DasLetzteBand.jpg",
    },
    {
      id: 4,
      title: "Diskurs",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/Diskurs.jpg",
      imgDefault: "series/fluchten/big/Diskurs.jpg",
      imgFull: "series/fluchten/big/Diskurs.jpg",
    },
    {
      id: 5,
      title: "Ein Riß geht durch unsere Gesellschaft",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/EinRißGehtDurchUnsereGesellschaft.jpg",
      imgDefault: "series/fluchten/big/EinRißGehtDurchUnsereGesellschaft.jpg",
      imgFull: "series/fluchten/big/EinRißGehtDurchUnsereGesellschaft.jpg",
    },
    {
      id: 6,
      title: "Flucht",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/Flucht.jpg",
      imgDefault: "series/fluchten/big/Flucht.jpg",
      imgFull: "series/fluchten/big/Flucht.jpg",
    },
    {
      id: 7,
      title: "Geheimes Treffen",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/GeheimesTreffen.jpg",
      imgDefault: "series/fluchten/big/GeheimesTreffen.jpg",
      imgFull: "series/fluchten/big/GeheimesTreffen.jpg",
    },
    {
      id: 8,
      title: "Ins Nichts",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/InsNichts.jpg",
      imgDefault: "series/fluchten/big/InsNichts.jpg",
      imgFull: "series/fluchten/big/InsNichts.jpg",
    },
    {
      id: 9,
      title: "Konspiration",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/Konspiration.jpg",
      imgDefault: "series/fluchten/big/Konspiration.jpg",
      imgFull: "series/fluchten/big/Konspiration.jpg",
    },
    {
      id: 10,
      title: "Observation",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/Observation.jpg",
      imgDefault: "series/fluchten/big/Observation.jpg",
      imgFull: "series/fluchten/big/Observation.jpg",
    },
    {
      id: 11,
      title: "Plan 2012",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/Plan2012.jpg",
      imgDefault: "series/fluchten/big/Plan2012.jpg",
      imgFull: "series/fluchten/big/Plan2012.jpg",
    },
    {
      id: 12,
      title: "Simultan",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/Simultan.jpg",
      imgDefault: "series/fluchten/big/Simultan.jpg",
      imgFull: "series/fluchten/big/Simultan.jpg",
    },
    {
      id: 13,
      title: "Täuschung",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/Täuschung.jpg",
      imgDefault: "series/fluchten/big/Täuschung.jpg",
      imgFull: "series/fluchten/big/Täuschung.jpg",
    },
    {
      id: 14,
      title: "Verwirbelung",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/fluchten/big/Verwirbelung.jpg",
      imgDefault: "series/fluchten/big/Verwirbelung.jpg",
      imgFull: "series/fluchten/big/Verwirbelung.jpg",
    },
  ],
};
