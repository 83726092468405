export const frontPageEntries = [
  {
    imgs: [
      'front-page/Abschied.jpg',
    ]
  },
  {
    imgs: [
      'front-page/FugeGroß.jpg',
    ]
  },
  {
    imgs: [
      'front-page/GezeitenDerDialoge.jpg',
    ]
  },
  {
    imgs: [
      'front-page/WartenScaled.jpg',
    ]
  },
  {
    imgs: [
      'front-page/AlloverRed.jpg',
      // 'front-page/49.jpg',
    ]
  }
]

