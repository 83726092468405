<template>
  <Carousel :autoplay="this.time" :wrap-around="true" :transition="this.transition">
    <Slide v-for="slide in imgSlides" :key="slide">
      <div class="carousel__item">
        <SlideContent :imgs="slide.imgs" />
      </div>
    </Slide>

    <template #addons>
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
import SlideContent from "./SlideContent.vue";

export default {
  name: "ContentAutoSlider",
  components: {
    SlideContent,
    Carousel,
    Slide,
    Pagination,
  },
  props: {
    time: Number,
    transition: Number,
  },
  data: function() {
    return {
      imgSlides: this.$store.getters.getFrontPageEntries,
    };
  },
};
</script>

<style scoped>
.carousel__slide--visible {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.carousel__item {
  width: 100%;
}
</style>