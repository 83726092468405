import { createStore } from "vuex";
import { fluchtenData } from "./series/fluchten";
import { raumUndTraumData } from "./series/raumUndTraum";
import { schreitenderData } from "./series/schreitender";
import { wartenData } from "./series/warten";
import { wasserwerferData } from "./series/wasserwerfer";
import { vitaEntries } from "./vita/vitaEntries";
import { expositionEntries } from "./expositions/expositionEntries";
import { frontPageEntries } from "./frontPage/frontPageEntries";

export default createStore({
  state: {
    allSeries: [
      fluchtenData,
      raumUndTraumData,
      schreitenderData,
      wartenData,
      wasserwerferData,
    ],
    vitaEntries: vitaEntries,
    expositionEntries: expositionEntries,
    frontPageEntries: frontPageEntries
  },
  mutations: {},
  actions: {},
  modules: {},
  getters: {
    getAllSeries: (state) => state.allSeries,
    getSeriesByName: (state) => (name) => {
      return state.allSeries.find((series) => series.name === name);
    },
    getVitaEntries: (state) => state.vitaEntries,
    getExpositionEntries: (state) => state.expositionEntries,
    getFrontPageEntries: (state) => state.frontPageEntries
  },
});
