<template>
  <ContentAutoSlider :time="3000" :transition="2000" style="margin-top: 6vh;"/>
</template>

<script>
// @ is an alias to /src

import ContentAutoSlider from "../components/FrontPage/ContentAutoSlider.vue";

export default {
  name: "Index",
  components: { ContentAutoSlider }
};
</script>
