export const wartenData = {
  name: "Warten",
  img: "series/warten/big/PII.jpg",
  gridCols: 6,
  // text: "Der Traum, der sich auf das Kopfkissen bettet und die ganze Nacht in neuem Licht erscheinen lässt.",
  text: "",
  pictures: [
    {
      id: 1,
      title: "Ambivalenz",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/Ambivalenz.jpg",
      imgDefault: "series/warten/big/Ambivalenz.jpg",
      imgFull: "series/warten/big/Ambivalenz.jpg",
    },
    {
      id: 2,
      title: "Der Vorhang geht auf",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/DerVorhangGehtAuf.jpg",
      imgDefault: "series/warten/big/DerVorhangGehtAuf.jpg",
      imgFull: "series/warten/big/DerVorhangGehtAuf.jpg",
    },
    {
      id: 3,
      title: "Engel",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/Engel.jpg",
      imgDefault: "series/warten/big/Engel.jpg",
      imgFull: "series/warten/big/Engel.jpg",
    },
    {
      id: 4,
      title: "Gezeiten der Dialoge",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/GezeitenDerDialoge.jpg",
      imgDefault: "series/warten/big/GezeitenDerDialoge.jpg",
      imgFull: "series/warten/big/GezeitenDerDialoge.jpg",
    },
    {
      id: 5,
      title: "Güldenes Licht",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/GüldenesLicht.jpg",
      imgDefault: "series/warten/big/GüldenesLicht.jpg",
      imgFull: "series/warten/big/GüldenesLicht.jpg",
    },
    {
      id: 6,
      title: "Ich bin dann mal Weg",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/IchBinDannMalWeg.jpg",
      imgDefault: "series/warten/big/IchBinDannMalWeg.jpg",
      imgFull: "series/warten/big/IchBinDannMalWeg.jpg",
    },
    {
      id: 7,
      title: "Lucia",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/Lucia.jpg",
      imgDefault: "series/warten/big/Lucia.jpg",
      imgFull: "series/warten/big/Lucia.jpg",
    },
    {
      id: 8,
      title: "P. II",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/PII.jpg",
      imgDefault: "series/warten/big/PII.jpg",
      imgFull: "series/warten/big/PII.jpg",
    },
    {
      id: 9,
      title: "Sich berühren lassen",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/SichBerührenLassen.jpg",
      imgDefault: "series/warten/big/SichBerührenLassen.jpg",
      imgFull: "series/warten/big/SichBerührenLassen.jpg",
    },
    {
      id: 10,
      title: "Träumer",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/Träumer.jpg",
      imgDefault: "series/warten/big/Träumer.jpg",
      imgFull: "series/warten/big/Träumer.jpg",
    },
    {
      id: 11,
      title: "Zuversicht",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/Zuversicht.jpg",
      imgDefault: "series/warten/big/Zuversicht.jpg",
      imgFull: "series/warten/big/Zuversicht.jpg",
    },
    {
      id: 12,
      title: "Zweierlei Sinnes",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/warten/big/ZweierleiSinnes.jpg",
      imgDefault: "series/warten/big/ZweierleiSinnes.jpg",
      imgFull: "series/warten/big/ZweierleiSinnes.jpg",
    },
  ],
};
