import {ref, onUnmounted} from 'vue';

// taken from https://stackoverflow.com/questions/76661744/v-if-condition-based-on-screen-size
export function useInnerWidth() {
  const width = ref(window.innerWidth);

  const syncWidth = () => (width.value = window.innerWidth);

  window.addEventListener("resize", syncWidth);

  onUnmounted(() => window.removeEventListener("resize", syncWidth));

  return width;
}

