export const raumUndTraumData = {
  name: "Raum und Traum",
  img: "series/raumUndTraum/big/TraumVI.jpg",
  gridCols: 7,
  text: "Der Traum, der sich auf das Kopfkissen bettet und die ganze Nacht in neuem Licht erscheinen lässt.",
  pictures: [
    {
      id: 1,
      title: "Traum I",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/raumUndTraum/big/TraumI.jpg",
      imgDefault: "series/raumUndTraum/big/TraumI.jpg",
      imgFull: "series/raumUndTraum/big/TraumI.jpg",
    },
    {
      id: 2,
      title: "Traum II",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/raumUndTraum/big/TraumII.jpg",
      imgDefault: "series/raumUndTraum/big/TraumII.jpg",
      imgFull: "series/raumUndTraum/big/TraumII.jpg",
    },
    {
      id: 3,
      title: "Traum IV",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/raumUndTraum/big/TraumIV.jpg",
      imgDefault: "series/raumUndTraum/big/TraumIV.jpg",
      imgFull: "series/raumUndTraum/big/TraumIV.jpg",
    },
    {
      id: 4,
      title: "Traum V",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/raumUndTraum/big/TraumV.jpg",
      imgDefault: "series/raumUndTraum/big/TraumV.jpg",
      imgFull: "series/raumUndTraum/big/TraumV.jpg",
    },
    {
      id: 5,
      title: "Traum VI",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/raumUndTraum/big/TraumVI.jpg",
      imgDefault: "series/raumUndTraum/big/TraumVI.jpg",
      imgFull: "series/raumUndTraum/big/TraumVI.jpg",
    },
    {
      id: 6,
      title: "Traum VIII",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/raumUndTraum/big/TraumVIII.jpg",
      imgDefault: "series/raumUndTraum/big/TraumVIII.jpg",
      imgFull: "series/raumUndTraum/big/TraumVIII.jpg",
    },
    {
      id: 7,
      title: "Traum IX - Spread your tears",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/raumUndTraum/big/TraumIX_spreadYourTears.jpg",
      imgDefault: "series/raumUndTraum/big/TraumIX_spreadYourTears.jpg",
      imgFull: "series/raumUndTraum/big/TraumIX_spreadYourTears.jpg",
    },
    {
      id: 8,
      title: "Landschaft I",
      year: 2012,
      technique: "Acryl, Tinte, Leinwand",
      width: 20,
      height: 20,
      imgSmall: "series/raumUndTraum/big/LandschaftI.jpg",
      imgDefault: "series/raumUndTraum/big/LandschaftI.jpg",
      imgFull: "series/raumUndTraum/big/LandschaftI.jpg",
    },
    {
      id: 9,
      title: "Landschaft II",
      year: 2012,
      technique: "Acryl, Tinte, Leinwand",
      width: 20,
      height: 20,
      imgSmall: "series/raumUndTraum/big/LandschaftII.jpg",
      imgDefault: "series/raumUndTraum/big/LandschaftII.jpg",
      imgFull: "series/raumUndTraum/big/LandschaftII.jpg",
    },
    {
      id: 10,
      title: "Landschaft III",
      year: 2012,
      technique: "Acryl, Tinte, Leinwand",
      width: 20,
      height: 20,
      imgSmall: "series/raumUndTraum/big/LandschaftIII.jpg",
      imgDefault: "series/raumUndTraum/big/LandschaftIII.jpg",
      imgFull: "series/raumUndTraum/big/LandschaftIII.jpg",
    },
    {
      id: 11,
      title: "Landschaft IX",
      year: 2012,
      technique: "Acryl, Tinte, Leinwand",
      width: 20,
      height: 20,
      imgSmall: "series/raumUndTraum/big/LandschaftIX.jpg",
      imgDefault: "series/raumUndTraum/big/LandschaftIX.jpg",
      imgFull: "series/raumUndTraum/big/LandschaftIX.jpg",
    },
    {
      id: 12,
      title: "Landschaft V",
      year: 2012,
      technique: "Acryl, Tinte, Leinwand",
      width: 20,
      height: 20,
      imgSmall: "series/raumUndTraum/big/LandschaftV.jpg",
      imgDefault: "series/raumUndTraum/big/LandschaftV.jpg",
      imgFull: "series/raumUndTraum/big/LandschaftV.jpg",
    },
    {
      id: 13,
      title: "Landschaft VI",
      year: 2012,
      technique: "Acryl, Tinte, Leinwand",
      width: 20,
      height: 20,
      imgSmall: "series/raumUndTraum/big/LandschaftVI.jpg",
      imgDefault: "series/raumUndTraum/big/LandschaftVI.jpg",
      imgFull: "series/raumUndTraum/big/LandschaftVI.jpg",
    },
    {
      id: 14,
      title: "Traue mich",
      year: 2011,
      technique: "Öl, Acryl, Leinwand",
      width: 20,
      height: 20,
      imgSmall: "series/raumUndTraum/big/TraueMich.jpg",
      imgDefault: "series/raumUndTraum/big/TraueMich.jpg",
      imgFull: "series/raumUndTraum/big/TraueMich.jpg",
    },
  ],
};
