<template>
  <div id="container" class="lg:mx-32 mx-8">

    <router-view v-slot="{ Component }">
      <div v-if="innerWidth > mobileBreakpointWidth" id="navbar-container" class="lg:mt-28 mt-8 grid-cols-12">
        <Navbar />
      </div>
      <div v-else class="mt-8">
        <MobileMenu />
      </div>

      <div id="content-container" class="mt-12 grid-cols-12">
        <component :is="Component"></component>
      </div>
    </router-view>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar";
import ContentAutoSlider from "./components/FrontPage/ContentAutoSlider.vue";
import { useInnerWidth } from "@/util";
import { mobileBreakpointWidth } from "@/conf";
import MobileMenu from "@/components/Navbar/MobileMenu.vue";

export default {
  name: "App",
  components: {
    MobileMenu,
    ContentAutoSlider,
    Navbar,
  },
  data: function () {
    return {
      innerWidth: useInnerWidth(),
      mobileBreakpointWidth: mobileBreakpointWidth,
    };
  },
};

// For running in certain environments: include this in the package.json
// "serve": "NODE_OPTIONS=--openssl-legacy-provider vue-cli-service serve",

</script>
