export const schreitenderData = {
  name: "Schreitender",
  img: "series/schreitender/big/SchreitenderXV.jpg",
  gridCols: 6,
  // text: "Der Traum, der sich auf das Kopfkissen bettet und die ganze Nacht in neuem Licht erscheinen lässt.",
  text: "",
  pictures: [
    {
      id: 1,
      title: "Aus Dem Nichts",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      // FixMe: Should be AusDemNichts image file
      imgSmall: "series/schreitender/big/AusDemNichts.jpg",
      imgDefault: "series/schreitender/big/AusDemNichts.jpg",
      imgFull: "series/schreitender/big/AusDemNichts.jpg",
      // imgSmall: "series/schreitender/big/Endlich.jpg",
      // imgDefault: "series/schreitender/big/Endlich.jpg",
      // imgFull: "series/schreitender/big/Endlich.jpg",
    },
    {
      id: 2,
      title: "Endlich",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/schreitender/big/Endlich.jpg",
      imgDefault: "series/schreitender/big/Endlich.jpg",
      imgFull: "series/schreitender/big/Endlich.jpg",
    },
    {
      id: 3,
      title: "Gedanken An R.",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/schreitender/big/GedankenAnR..jpg",
      imgDefault: "series/schreitender/big/GedankenAnR..jpg",
      imgFull: "series/schreitender/big/GedankenAnR..jpg",
    },
    {
      id: 4,
      title: "Goldene Tangente",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/schreitender/big/GoldeneTangente.jpg",
      imgDefault: "series/schreitender/big/GoldeneTangente.jpg",
      imgFull: "series/schreitender/big/GoldeneTangente.jpg",
    },
    {
      id: 5,
      title: "Harlekin",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      // FixMe: Should be Harlekin image file
      imgSmall: "series/schreitender/big/Harlekin.jpg",
      imgDefault: "series/schreitender/big/Harlekin.jpg",
      imgFull: "series/schreitender/big/Harlekin.jpg",
      // imgSmall: "series/schreitender/big/GoldeneTangente.jpg",
      // imgDefault: "series/schreitender/big/GoldeneTangente.jpg",
      // imgFull: "series/schreitender/big/GoldeneTangente.jpg",
    },
    {
      id: 6,
      title: "Jeder ist seines Glückes Schmied",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/schreitender/big/JederIstSeinesGlückesSchmied.jpg",
      imgDefault: "series/schreitender/big/JederIstSeinesGlückesSchmied.jpg",
      imgFull: "series/schreitender/big/JederIstSeinesGlückesSchmied.jpg",
    },
    {
      id: 7,
      title: "Out Of Search",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/schreitender/big/OutOfSearch.jpg",
      imgDefault: "series/schreitender/big/OutOfSearch.jpg",
      imgFull: "series/schreitender/big/OutOfSearch.jpg",
    },
    {
      id: 8,
      title: "Phönix aus der Asche",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/schreitender/big/PhönixAusDerAsche.jpg",
      imgDefault: "series/schreitender/big/PhönixAusDerAsche.jpg",
      imgFull: "series/schreitender/big/PhönixAusDerAsche.jpg",
    },
    {
      id: 9,
      title: "Schreitender I",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/schreitender/big/SchreitenderI.jpg",
      imgDefault: "series/schreitender/big/SchreitenderI.jpg",
      imgFull: "series/schreitender/big/SchreitenderI.jpg",
    },
    {
      id: 10,
      title: "Schreitender XV",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/schreitender/big/SchreitenderXV.jpg",
      imgDefault: "series/schreitender/big/SchreitenderXV.jpg",
      imgFull: "series/schreitender/big/SchreitenderXV.jpg",
    },
    {
      id: 11,
      title: "Schreitender XVI",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/schreitender/big/SchreitenderXVI.jpg",
      imgDefault: "series/schreitender/big/SchreitenderXVI.jpg",
      imgFull: "series/schreitender/big/SchreitenderXVI.jpg",
    },
    {
      id: 12,
      title: "Schreitender XVII",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/schreitender/big/SchreitenderXVII.jpg",
      imgDefault: "series/schreitender/big/SchreitenderXVII.jpg",
      imgFull: "series/schreitender/big/SchreitenderXVII.jpg",
    },
  ],
};
