<template>
  <div class="flex flex-row justify-center h-5/6 h-full">
    <div v-for="img in this.processedImgs" :key="img">
      <img :src="img" style="max-height: 60vh">
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideContent",
  props: {
    imgs: [String],
  },
  data: function () {
    const processedImgs = [];
    for (let i = 0; i < this.imgs.length; i++) {
      processedImgs.push(require(`@/assets/img/${this.imgs[i]}`));
    }
    return {
      processedImgs: processedImgs,
    };
  },
};
</script>
