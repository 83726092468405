export const wasserwerferData = {
  name: "Wasserwerfer",
  img: "series/wasserwerfer/big/DerAufprall6.jpg",
  gridCols: 6,
  text: "Sich die Menschen vom Leibe halten: Der Aufprall eines Wasserwerfers auf den menschlichen Körper.",
  pictures: [
    {
      id: 1,
      title: "Der Aufprall 1",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall1.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall1.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall1.jpg",
    },
    {
      id: 2,
      title: "Der Aufprall 2",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall2.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall2.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall2.jpg",
    },
    {
      id: 3,
      title: "Der Aufprall 3",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall3.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall3.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall3.jpg",
    },
    {
      id: 4,
      title: "Der Aufprall 4",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall4.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall4.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall4.jpg",
    },
    {
      id: 5,
      title: "Der Aufprall 5",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall5.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall5.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall5.jpg",
    },
    {
      id: 6,
      title: "Der Aufprall 6",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall6.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall6.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall6.jpg",
    },
    {
      id: 7,
      title: "Der Aufprall 7",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall7.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall7.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall7.jpg",
    },
    {
      id: 8,
      title: "Der Aufprall 8",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall8.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall8.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall8.jpg",
    },
    {
      id: 9,
      title: "Der Aufprall 10",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall10.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall10.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall10.jpg",
    },
    {
      id: 10,
      title: "Der Aufprall 11",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall11.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall11.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall11.jpg",
    },
    {
      id: 11,
      title: "Der Aufprall 14",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall14.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall14.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall14.jpg",
    },
    {
      id: 12,
      title: "Der Aufprall 16",
      year: 2018,
      technique: "Mischtechnik, Hartfaser",
      width: 26,
      height: 14.5,
      imgSmall: "series/wasserwerfer/big/DerAufprall16.jpg",
      imgDefault: "series/wasserwerfer/big/DerAufprall16.jpg",
      imgFull: "series/wasserwerfer/big/DerAufprall16.jpg",
    },
  ],
};
