<template>
  <div id="nav-icon" @click="toggleMenu">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {
  name: "NavIcon",
  props: {
    isMobile: Boolean,
  },
  methods: {
    toggleMenu: function () {
      document.getElementById("nav-icon").classList.toggle("open");
      if (this.isMobile) {
        document.getElementById("mobile-nav-items").classList.toggle("open");
      } else {
        document.getElementById("nav-items").classList.toggle("open");
      }
    },
  },
};
</script>

<style scoped lang="postcss">
#nav-icon {
  width: 40px;
  height: 38px;
  position: relative;
  cursor: pointer;

  transition: 0.5s ease-in-out;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #0000004d;
  border-radius: 5px;
  opacity: 1;
  left: 0;

  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0;
}

#nav-icon span:nth-child(2) {
  top: 18px;
}

#nav-icon span:nth-child(3) {
  top: 36px;
}

#nav-icon.open span:nth-child(1) {
  top: 18px;
  transform: rotate(135deg);
}

#nav-icon.open span:nth-child(3) {
  top: 18px;
  transform: rotate(-135deg);
}

#nav-icon.open span:nth-child(2) {
  left: -40px;
  opacity: 0;
}
</style>
