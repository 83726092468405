import { createRouter, createWebHistory } from "vue-router";
import Index from "../views/Index.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Index,
  },
  {
    path: "/impressum",
    name: "Imprint",
    component: () =>
      import("../views/Imprint.vue"),
  },
  {
    path: "/einzelwerke",
    name: "SingleWorks",
    component: () => import("../views/SingleWorks.vue"),
  },
  {
    path: "/serien-uebersicht",
    name: "SeriesOverview",
    component: () => import("../views/SeriesOverview.vue"),
  },
  {
    path: "/serien/:name",
    name: "Series",
    component: () => import("../views/Series.vue"),
  },
  {
    path: "/ausstellungen",
    name: "Expositions",
    component: () => import("../views/Expositions.vue"),
  },
  {
    path: "/vita",
    name: "Vita",
    component: () => import("../views/Vita.vue"),
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
