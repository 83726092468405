export const expositionEntries = [
  {
    section: 'Einzelausstellungen (Auswahl)',
    entries: [
      {
        year: '2023',
        text: '„Nahaufnahme“, Galerie Pfundt, Berlin'
      },
      {
        year: '2021',
        text: '„Open Studios“, Nonnendamm 17, Berlin'
      },
      {
        year: '2021',
        text: '„Gegenwart, Ewigkeit“, Galerie Pfundt, Berlin'
      },
      {
        year: '2017',
        text: '„Sehnsucht. Langeweile“, diekleinegalerie, Berlin'
      },
      {
        year: '2017',
        text: '„Künstlerhaus N17“, Berlin'
      },
      {
        year: '2016',
        text: '„ungleiches Behagen“, Galerie Pfundt, Berlin'
      },
      {
        year: '2014',
        text: '„Neue Räume“, Galerie Pfundt, Berlin'
      },
      {
        year: '2013',
        text: '„an jenem Tag war alles anders“, Galerie LUX, Berlin'
      },
      {
        year: '2012',
        text: '„Künstlerhaus N17“, Berlin'
      },
      {
        year: '2011',
        text: '„Kammermusiksaal Friedenau“, Berlin'
      },
      {
        year: '2010',
        text: '„Warten, dass die Zeit vergeht“, Oberverwaltungsgericht, Berlin-Brandenburg'
      },
      {
        year: '2009',
        text: '„Installation Dachgeschoss N17“, Berlin'
      },
      {
        year: '2003',
        text: '„Einladung des Präsidenten“, Universität der Künste, Berlin'
      },
      {
        year: '2002',
        text: '„Bilder von Gabriele Schlesselmann“, Kunsthalle LinX, Berlin'
      },
      {
        year: '2001',
        text: '„Farbe Rot“, Galerie Sabine Pfundt, Berlin'
      },
      {
        year: '2000',
        text: '„Öl auf großen Leinwänden“,  Galerie Sabine Pfundt, Berlin'
      },
      {
        year: '1998',
        text: '„Galerie Kunstruktiv Tendens“, Stockholm'
      },
      {
        year: '1998',
        text: '„Bilder von Gabriele Schlesselmann“, K & K, Berlin'
      },
      {
        year: '1997',
        text: '“Villa Flath”, Bad Segeberg'
      },
      {
        year: '1995',
        text: '“erst rot, dann blau, dann gelb”,  Galerie Pfund, Berlin'
      },
      {
        year: '1993',
        text: '“Galerie Tallin”, Berlin'
      },
    ],
  },
  {
    section: 'Gruppenausstellungen (Auswahl)',
    entries: [
      {
        year: '2021',
        text: '„Stadt-findet-Kunst, Open Studios“, Nonnendamm 17'
      },
      {
        year: '2019',
        text: '„Brückenschlag“, Rathaus-Galerie Reinickendorf'
      },
      {
        year: '2018',
        text: '„Abseits der Zeit“, Galata Rum Okulu, Istanbul'
      },
      {
        year: '2018',
        text: '„Ölbilder, Plastiken, Photografien“, Galerie Pfundt, Berlin'
      },
      {
        year: '2018',
        text: '„Drei Stationen Kunst“, diekleinegalerie, Berlin'
      },
      {
        year: '2016',
        text: 'art KARLSRUHE'
      },
      {
        year: '2016',
        text: 'Galerie Pfundt, Berlin'
      }
    ]
  }
];
